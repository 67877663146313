.autocomplete-select {
    width: 30%;
}

.create-page {
    & div.MuiCard-root {
        overflow: inherit !important;
    }
}

.edit-page {
    & div.MuiCard-root {
        overflow: inherit !important;
    }
}
