.draggable {
    padding: 0;

    &__item {
        display: flex;
        align-items: center;
        position: relative;
        list-style: none;

        .typography {
            padding-left: 30px;
        }

        .MuiPaper-rounded {
            width: 100%;
        }
    }

    &__num {
        margin-right: 16px;
    }

    &__handle {
        position: absolute;
        top: 24px;
        display: block;
        width: 18px;
        height: 11px;
        opacity: 0.25;
        margin-left: 16px;
        cursor: row-resize;
        z-index: 999;
        transform: translateY(-50%);
        background: -webkit-linear-gradient(
            top,
            #000,
            #000 20%,
            #fff 0,
            #fff 40%,
            #000 0,
            #000 60%,
            #fff 0,
            #fff 80%,
            #000 0,
            #000
        );
        background: linear-gradient(
            180deg,
            #000,
            #000 20%,
            #fff 0,
            #fff 40%,
            #000 0,
            #000 60%,
            #fff 0,
            #fff 80%,
            #000 0,
            #000
        );
    }

    &__item {
        z-index: 999;
    }
}

.sortableHelper {
    z-index: 10;
}

.MuiAccordion-root.Mui-expanded {
    margin-top: 1px !important;
}

.MuiTextField-root.color-input {
    margin: 0;
    margin-top: 8px;
    margin-right: 8px;
}