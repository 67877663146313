.timeline-input-item {
    width: 50%;
    display: inline-block;
    & > div {
        width: 100%;
    }
}.timeline-input-item-time {
    width: 25%;
    & >  div{
        width: 90%;
    }
}

.ra-input {
    width: fit-content;
    display: contents
}